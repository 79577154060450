import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Section from '../../../molecules/Section/Section';
import { breakpoints } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import AnimatedHexCard from '../../Cards/AnimatedHexCard';
import CardDeckHeader from '../components/CardDeckHeader';
import { CardWrapper, Wrapper } from '../styles/AnimatedHexCardDeck.styled';

const AnimatedHexCardDeck = ({
  component: { id, cards, background, headingKicker, heading, subhead, cta },
}) => {
  const isTablet = useMediaQuery(
    `(min-width: ${breakpoints.md}) and (max-width: ${breakpoints.xl})`
  );

  return (
    <Section id={id} bgColor={background || colors.base.heroSurface}>
      <Wrapper>
        <CardDeckHeader
          headingKicker={headingKicker}
          heading={heading}
          subhead={subhead}
        />
        <CardWrapper>
          {cards &&
            cards.map((card, ind) => {
              if (isTablet && ind > 1) return;

              return <AnimatedHexCard key={card.id} {...card} />;
            })}
        </CardWrapper>
        {cta ? (
          <div className="cta-container">
            <CallToAction
              variant={cta.variant}
              size={cta.size}
              value={cta.label}
              link={cta.url}
              icon={cta.icon}
              reverse={cta.reverse}
            />
          </div>
        ) : (
          ''
        )}
      </Wrapper>
    </Section>
  );
};

export default AnimatedHexCardDeck;
